import React, { Fragment } from "react";
import { Link, navigate } from "gatsby";
import logo from "../img/logo.png";
import { firebaseApp } from "./firebase/firestore";
import { AuthContext } from "./auth/Auth";
import Avatar from "./user/Avatar";
import CookiesPopup from "./CookiesPopup";
import { isInviteOnlyMode } from "../utils/utils";
import { resetNewsletterSignupFormPopup } from "./NewslletterSignUpForm";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        // eslint-disable-next-line no-unused-expressions
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      },
    );
  };

  render() {
    return (
      <AuthContext.Consumer>
        {({ currentUser, avatarUrl, isAdmin, refUid }) => (
          <nav
            className="navbar is-fixed-top"
            role="navigation"
            aria-label="main-navigation"
          >
            <CookiesPopup />
            <div className="container is-widescreen navbar-fluid-desktop">
              <div className="navbar-brand">
                <Link to="/" className="navbar-item" title="Logo">
                  <img src={logo} alt="OCCASIO" style={{ marginTop: "-8px" }} />
                </Link>
                {/* Hamburger menu */}
                <div
                  className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                  data-target="navMenu"
                  onClick={() => this.toggleHamburger()}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <div
                id="navMenu"
                className={`navbar-menu ${this.state.navBarActiveClass}`}
              >
                <div className="navbar-start has-text-centered">
                  <Link
                    className="navbar-item"
                    to="/about"
                    activeClassName="has-text-weight-bold"
                  >
                    About
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/timeline"
                    activeClassName="has-text-weight-bold"
                  >
                    Timeline
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/how-it-works"
                    activeClassName="has-text-weight-bold"
                  >
                    How it Works
                  </Link>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <a className="navbar-link is-hidden-touch">Get Involved</a>
                    <div className="navbar-dropdown">
                      <Link
                        className="navbar-item"
                        to="/donate"
                        activeClassName="has-text-weight-bold"
                      >
                        Donate
                      </Link>
                      <Link
                        className="navbar-item"
                        to="/hall-of-fame"
                        activeClassName="has-text-weight-bold"
                      >
                        Hall of Fame
                      </Link>
                    </div>
                  </div>
                  <Link
                    className="navbar-item"
                    to="/blog"
                    activeClassName="has-text-weight-bold"
                  >
                    Blog
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/faq"
                    activeClassName="has-text-weight-bold"
                  >
                    FAQ
                  </Link>
                </div>
                <div className="navbar-end has-text-centered">
                  <Link
                    className="navbar-item"
                    to="/get-help"
                    activeClassName="has-text-weight-bold"
                  >
                    <i className="far fa-question-circle m-l-xs m-r-xs" />
                  </Link>
                  {!currentUser ? (
                    <Fragment>
                      {isInviteOnlyMode() && !refUid ? (
                        <Link
                          className="navbar-item"
                          to="/get-invited"
                          activeClassName="has-text-weight-bold"
                        >
                          <b>Get Invited</b>
                        </Link>
                      ) : (
                        <Link
                          className="navbar-item"
                          to="/register"
                          activeClassName="has-text-weight-bold"
                        >
                          <b>Register</b>
                        </Link>
                      )}

                      <Link
                        className="navbar-item"
                        to="/login"
                        activeClassName="has-text-weight-bold"
                      >
                        Log in
                      </Link>
                    </Fragment>
                  ) : (
                    ""
                  )}

                  {currentUser ? (
                    <Fragment>
                      <div className="navbar-item has-dropdown is-hoverable">
                        <a
                          className="navbar-link is-hidden-touch is-arrowless"
                          style={
                            {
                              // paddingRight: "1.2em",
                              // paddingLeft: "1.2em"
                            }
                          }
                        >
                          <Avatar url={avatarUrl} size="48" />
                        </a>

                        <div className="navbar-dropdown is-right">
                          <div className="navbar-item has-text-weight-semibold">
                            {currentUser.email}
                          </div>
                          <Link
                            className="navbar-item"
                            to="/settings"
                            activeClassName="has-text-weight-bold"
                          >
                            Profile Settings
                          </Link>
                          <Link
                            className="navbar-item"
                            to="/ref-users"
                            activeClassName="has-text-weight-bold"
                          >
                            My Connections
                          </Link>
                          {isAdmin && (
                            <Fragment>
                              <hr className="navbar-divider" />
                              <Link
                                className="navbar-item"
                                to="/userlist"
                                activeClassName="has-text-weight-bold"
                              >
                                Manage Users
                              </Link>
                              <Link
                                className="navbar-item"
                                to="/donations"
                                activeClassName="has-text-weight-bold"
                              >
                                Donations
                              </Link>
                            </Fragment>
                          )}
                          <hr className="navbar-divider" />
                          <a
                            className="navbar-item"
                            onClick={() => {
                              firebaseApp
                                .auth()
                                .signOut()
                                .then(() => {
                                  // reset newsletter signup form popup
                                  resetNewsletterSignupFormPopup();

                                  navigate("/");
                                })
                                .catch((error) =>
                                  alert(`Error logging out. ${error.message}`),
                                );
                            }}
                          >
                            Log out
                          </a>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </nav>
        )}
      </AuthContext.Consumer>
    );
  }
};

export default Navbar;
