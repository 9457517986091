import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { storageRef } from "../firebase/firestore";
import placeholder128 from "../../../static/img/placeholder-128x128.png";

// if id is provided, we use it to download avatar, otherwise use url (to avoid flickering on page change)
const Avatar = ({
  id,
  url = placeholder128,
  className = "",
  size = "28",
  onClick,
}) => {
  const [src, setSrc] = useState(url);

  useEffect(() => {
    if (id) {
      storageRef
        .child(`avatar/${id}`)
        .getDownloadURL()
        .then(storeUrl => {
          setSrc(storeUrl);
        })
        .catch(() => {
          // don't show error if avatar not found
        });
    }
  }, [id]);

  useEffect(() => {
    if (url) {
      setSrc(url);
    }
  }, [url]);

  return (
    <figure
      className={`image is-cropped-${size}x${size} ${className}`}
      onClick={onClick}
      style={{
        cursor: url !== placeholder128 ? "pointer" : "inherit",
      }}
    >
      <img
        className="is-rounded"
        src={src}
        alt="avatar"
        style={{ height: "auto", maxHeight: "none" }}
      />
    </figure>
  );
};

Avatar.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

export default Avatar;
