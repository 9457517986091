import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import share from "../../static/img/safari_share.png";
import home from "../../static/img/safari-add-to-home.png";
import { timeSinceEvent } from "../utils/utils";

const safariMsg = (
  <Fragment>
    Install this app for free for instant access.
    <br /> Just tap&nbsp;&nbsp;
    <img src={share} alt="Share" style={{ height: "1.5rem" }} />
    &nbsp;&nbsp;and&nbsp;&nbsp;
    <img
      src={home}
      alt="Share"
      style={{ height: "1.5rem", marginBottom: "-0.25rem" }}
    />
    &nbsp;&nbsp;(Add to home screen). Swipe down if you don&apos;t see
    the&nbsp;&nbsp;
    <img src={share} alt="Share" style={{ height: "1.5rem" }} />
    &nbsp;&nbsp;.
    <br />
    Reload the page and start scrolling down if swipe down doesn&apos;t work.
  </Fragment>
);

const nonSafariMsg = (
  <Fragment>
    Open this site in Safari to install the app for quick access.
  </Fragment>
);

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

const isSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /webkit/.test(userAgent) && !/crios|fxios|opios/.test(userAgent);
};

const isInStandaloneMode = () =>
  "standalone" in window.navigator && window.navigator.standalone;

const isCookiesAccepted = () =>
  window.localStorage.getItem("cookies_accepted") === "true";

const androidPWAPopup = e => {
  // Stash the event so it can be triggered later.
  // doesn't work
  const deferredPrompt = e;
  deferredPrompt.preventDefault();

  if (isCookiesAccepted()) {
    deferredPrompt.prompt();
  } else {
    setTimeout(() => {
      if (isCookiesAccepted()) {
        deferredPrompt.prompt();
      }
    }, 5000);
  }
};

// popup for iOS devices encouraging user to install PWA
const InstallPWA = () => {
  const [msg, setMsg] = useState();

  const handleScroll = () => {
    if (document.getElementById("ios-pwa-popup") === null) {
      return;
    }

    if (
      document.body.scrollTop > 700 ||
      document.documentElement.scrollTop > 700
    ) {
      document.getElementById("ios-pwa-popup").style.display = "block";
      document.getElementById("scroll-up").style.display = "none";
    } else {
      document.getElementById("ios-pwa-popup").style.display = "none";
      document.getElementById("scroll-up").style.display = "block";
    }
  };

  useEffect(() => {
    const timeSinceClosed = timeSinceEvent("ios_pwa_msg_close_date");

    if (
      isIos() &&
      !isInStandaloneMode() &&
      isCookiesAccepted() &&
      timeSinceClosed >= 1
    ) {
      window.addEventListener("scroll", handleScroll);
      setMsg(isSafari() ? safariMsg : nonSafariMsg);
    }

    window.addEventListener("beforeinstallprompt", androidPWAPopup);

    return () => {
      // if (isIos() && !isInStandaloneMode() && isCookiesAccepted()) {
      window.removeEventListener("scroll", handleScroll);
      // }
      window.removeEventListener("beforeinstallprompt", androidPWAPopup);
    };
  }, []);

  return (
    <div id="ios-pwa-popup" className="notification is-black">
      <button
        type="button"
        className="delete"
        onClick={event => {
          event.target.parentNode.parentNode.removeChild(
            event.target.parentNode,
          );
          document.getElementById("scroll-up").style.display = "block";
          window.localStorage.setItem(
            "ios_pwa_msg_close_date",
            new Date().toString(),
          );
        }}
      />
      {msg}
    </div>
  );
};

InstallPWA.propTypes = {};

export default InstallPWA;
