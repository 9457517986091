import React from "react";
import { Link } from "gatsby";

import { AuthContext } from "./auth/Auth";
import { isInviteOnlyMode } from "../utils/utils";

const SignUpCol = ({ currentUser, refUid }) => (
  <>
    <section className="menu">
      <ul className="menu-list">
        {!currentUser && (
          <li>
            {isInviteOnlyMode() && !refUid ? (
              <Link className="navbar-item" to="/get-invited">
                Get Invited
              </Link>
            ) : (
              <Link className="navbar-item" to="/register">
                Register
              </Link>
            )}
          </li>
        )}
        <li>
          <Link className="navbar-item" to="/login">
            Login
          </Link>
        </li>
      </ul>
    </section>
    <div className="social nowrap">
      <a title="email" href="mailto:onOCCASIO@gmail.com" target="_blank">
        <span
          className="far fa-envelope"
          alt="email"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
      <a
        title="linkedin"
        href="https://www.linkedin.com/company/15820548/"
        target="_blank"
      >
        <span
          className="fab fa-linkedin-in"
          alt="linkedin"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
      <a
        title="facebook"
        href="https://www.facebook.com/onOCCASIO/"
        target="_blank"
      >
        <span
          className="fab fa-facebook-f"
          alt="Facebook"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
      {/* <a title="whatsapp" href="https://whatsapp.com">
        <span
          className="fab fa-whatsapp"
          alt="whatsapp"
          style={{ width: "1em", height: "1em" }}
        />
      </a> */}
      {/* <a title="skype" href="https://skype.com">
        <span
          className="fab fa-skype"
          alt="skype"
          style={{ width: "1em", height: "1em" }}
        />
      </a> */}
      {/* <a title="viber" href="https://viber.com">
        <span
          className="fab fa-viber"
          alt="viber"
          style={{ width: "1em", height: "1em" }}
        />
      </a> */}
      <a title="twitter" href="https://twitter.com/onoccasio" target="_blank">
        <span
          className="fab fa-twitter"
          alt="Twitter"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
      <a
        title="youtube"
        href="https://www.youtube.com/channel/UCT8OG5p1X3_dacRwnr0iBFQ"
        target="_blank"
      >
        <span
          className="fab fa-youtube"
          alt="youtube"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
      <a
        title="instagram"
        href="https://www.instagram.com/onoccasio/"
        target="_blank"
      >
        <span
          className="fab fa-instagram"
          alt="instagram"
          style={{ width: "1em", height: "1em" }}
        />
      </a>
    </div>
  </>
);

const Footer = () => (
  <AuthContext.Consumer>
    {({ currentUser, refUid }) => (
      <footer className="footer has-background-grey-dark has-text-white-ter">
        <div className="content has-text-centered has-text-white-ter  m-b-xl">
          <div className="container has-text-white-ter">
            <div className="columns">
              <div className="column is-3 is-hidden-tablet">
                <SignUpCol currentUser={currentUser} refUid={refUid} />
              </div>
              <div className="column is-2-tablet is-3-desktop is-3-fullhd">
                <section className="menu">
                  <ul className="menu-list">
                    {/* <li>
                      <Link to="/" className="navbar-item">
                        Main page
                      </Link>
                    </li> */}
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/timeline">
                        Timeline
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/how-it-works">
                        How it Works
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/donate">
                        Donate
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/hall-of-fame">
                        Hall of Fame
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-2-tablet is-3-desktop is-3-fullhd">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/careers">
                        Careers
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="navbar-item" to="/invest">
                        Invest
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="navbar-item" to="/sponsor">
                        Sponsor
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="navbar-item" to="/ad">
                        Advertise
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="navbar-item" to="/partners">
                        Partners
                      </Link>
                    </li> */}
                  </ul>
                </section>
              </div>
              <div className="column is-2-tablet is-2-desktop is-3-fullhd">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/affiliate">
                        Ambassadors
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/faq">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/gdpr">
                        GDPR
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="navbar-item" to="/terms">
                        Terms&nbsp;&&nbsp;Conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/privacy">
                        Privacy&nbsp;&&nbsp;Cookies
                      </Link>
                    </li> */}
                    <li>
                      <Link className="navbar-item" to="/get-help">
                        Get Help
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-3 is-hidden-mobile ">
                <SignUpCol currentUser={currentUser} refUid={refUid} />
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered">
          <p
            style={{
              padding: "2.5em 0.75em",
              fontSize: "0.9rem",
              backgroundColor: "white",
              color: "#333",
            }}
          >
            Copyright © 2019-2020 OCCASIO. All Rights Reserved. OCCASIO
            Online™&nbsp;&nbsp;
            <Link className="" to="/terms">
              Terms&nbsp;&&nbsp;Conditions
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link className="" to="/privacy">
              Privacy Policy
            </Link>
          </p>
        </div>
      </footer>
    )}
  </AuthContext.Consumer>
);

export default Footer;
