import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link, navigate } from "gatsby";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./all.scss";
import useSiteMetadata from "./SiteMetadata";
import {
  AuthContext,
  firebaseEmailSettings,
  getInvitedUsersCount,
} from "./auth/Auth";
import ScrollUp from "./ScrollUp";
import InstallPWA from "./InstallPWA";
import CookiesPopup from "./CookiesPopup";
import WelcomeMSg from "./WelcomeMSg";
import { NewsletterSignUpForm } from "./NewslletterSignUpForm";
import { isUnderConstructionMode } from "../utils/utils";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  useEffect(() => {
    if (isUnderConstructionMode()) {
      navigate("/request-invite");
    }

    var Tawk_API = Tawk_API || {};
    const Tawk_LoadStart = new Date();
    (function () {
      const s1 = document.createElement("script");
      const s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5d61ecde77aa790be3309628/default";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <>
      {!isUnderConstructionMode() && (
        <div id="sticky_body">
          <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`${withPrefix("/")}img/apple-touch-icon.png`}
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix("/")}img/favicon-32x32.png`}
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix("/")}img/favicon-16x16.png`}
              sizes="16x16"
            />

            {/* <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        /> */}
            <meta name="theme-color" content="#fff" />

            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content="/" />
            <meta
              property="og:image"
              content={`${withPrefix("/")}img/og-image.jpg`}
            />
          </Helmet>
          <Navbar />
          <AuthContext.Consumer>
            {({ currentUser, notVerifiedCount, firstName }) => {
              return (
                <>
                  <div id="sticky_root">
                    <div className="container">
                      {currentUser === null && <WelcomeMSg />}
                      <div id="notifications_panel">
                        {currentUser &&
                        currentUser.email &&
                        !currentUser.emailVerified ? (
                          <div className="notification is-warning">
                            <button
                              type="button"
                              className="delete"
                              onClick={(event) => {
                                event.target.parentNode.parentNode.removeChild(
                                  event.target.parentNode,
                                );
                              }}
                            />
                            Your email address is not verified. Please follow
                            the link in the email we sent you.
                            <br />
                            Didn&apos;t recieve the email?{" "}
                            <a
                              onClick={() => {
                                currentUser.sendEmailVerification(
                                  firebaseEmailSettings,
                                );
                              }}
                            >
                              Resend email
                            </a>{" "}
                            to {currentUser.email}
                          </div>
                        ) : (
                          ""
                        )}

                        {currentUser && !currentUser.phoneNumber ? (
                          <div className="notification is-warning">
                            <button
                              type="button"
                              className="delete"
                              onClick={(event) => {
                                event.target.parentNode.parentNode.removeChild(
                                  event.target.parentNode,
                                );
                              }}
                            />
                            Please provide a phone number.
                          </div>
                        ) : (
                          ""
                        )}

                        {currentUser && notVerifiedCount !== 0 ? (
                          <div className="notification is-warning">
                            <button
                              type="button"
                              className="delete"
                              onClick={(event) => {
                                event.target.parentNode.parentNode.removeChild(
                                  event.target.parentNode,
                                );
                              }}
                            />
                            {notVerifiedCount === 1 ? (
                              <span>
                                <Link to="/ref-users#userlist"> 1 user</Link> is
                                waiting for your verification.
                              </span>
                            ) : (
                              <span>
                                <Link to="/ref-users#userlist">
                                  {" "}
                                  {notVerifiedCount} users
                                </Link>{" "}
                                are waiting for your verification.
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div id="static_notifications_panel">
                        <div
                          id="signup_legal_notice"
                          className="notification is-warning"
                          style={{ display: "none" }}
                        >
                          {/* <button
                      type="button"
                      className="delete"
                      onClick={event => {
                        event.target.parentNode.parentNode.removeChild(
                          event.target.parentNode
                        );
                      }}
                    /> */}
                          OCCASIO is a trusted network of connected people. To
                          keep it a safe place for everyone, we operate a real
                          data policy which means users must use real
                          information and hold a single account. We review
                          accounts periodically and duplicate accounts, or
                          accounts that breach this policy will be terminated
                          and banned without notice.
                        </div>
                      </div>
                    </div>
                    {children}
                    <ScrollUp />
                    <InstallPWA />
                  </div>
                  {/* {!currentUser && ( */}
                  <NewsletterSignUpForm
                    currentUser={currentUser}
                    firstName={firstName}
                  />
                  {/* )} */}
                </>
              );
            }}
          </AuthContext.Consumer>
          <Footer />
        </div>
      )}
    </>
  );
};

export default TemplateWrapper;
