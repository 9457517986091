import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const CookiesPopup = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookiesAccepted = window.localStorage.getItem("cookies_accepted");

    if (!cookiesAccepted) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    document.querySelector("#sticky_root").style.marginTop = `${
      document.querySelector(".navbar").clientHeight
    }px`;
  }, [show]);

  return (
    <div className="cookies-popup" style={{ display: show ? "flex" : "none" }}>
      <div>
        We use cookies to improve your experience. Find out more{" "}
        <Link
          className=""
          to="/privacy"
          style={{
            color: "#ff7d1f",
            // color: "#fff",
            // textDecoration: "underline",
          }}
        >
          here
        </Link>
        .
      </div>
      <span className="buttons m-t-none">
        {/* <button
          type="button"
          className="button is-rounded is-text-white m-l-md"
        >
          Cookie settings
        </button> */}
        <button
          type="button"
          className="button is-rounded is-white m-l-md"
          onClick={() => {
            window.localStorage.setItem("cookies_accepted", "true");
            setShow(false);
          }}
        >
          I accept
        </button>
      </span>
    </div>
  );
};

CookiesPopup.propTypes = {};

export default CookiesPopup;
