import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const Modal = ({
  idPrefix,
  isOpen,
  title,
  cb,
  children,
  infoOnly,
  additionalBtn,
  okBtnTitle,
  cancelBtnTitle,
  showTitle,
}) => {
  const clear = () => {
    [...document.querySelectorAll(`#modal-form${idPrefix} input`)].forEach(
      element => {
        switch (element.type) {
          case "text":
          case "password":
            element.value = "";
            break;
          default:
        }
      },
    );
  };

  const submitAndClear = async e => {
    e.preventDefault();

    const button = document.getElementById(`submit-modal-btn${idPrefix}`);
    button.classList.add("is-loading");

    await cb.submit(e);
    clear();

    button.classList.remove("is-loading");
  };

  const denyAndClear = e => {
    clear();
    cb.deny(e);
  };

  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={denyAndClear} />
      <div className="modal-card">
        {showTitle && (
          <header className="modal-card-head">
            <div className="modal-card-title">
              {title}
              {additionalBtn}
            </div>
            <button
              type="button"
              className="delete"
              onClick={denyAndClear}
              aria-label="close"
            />
          </header>
        )}
        <section className="modal-card-body">
          <form id={`modal-form${idPrefix}`} onSubmit={submitAndClear}>
            {children}
          </form>
        </section>
        <footer className="modal-card-foot buttons is-right">
          {infoOnly ? (
            <button
              type="button"
              form={`modal-form${idPrefix}`}
              className="button is-success"
              onClick={denyAndClear}
            >
              Ok
            </button>
          ) : (
            <span className="">
              <button
                type="button"
                form={`modal-form${idPrefix}`}
                className="button"
                onClick={denyAndClear}
                style={{
                  minWidth: "8rem",
                }}
              >
                {cancelBtnTitle || "Cancel"}
              </button>
              <button
                type="submit"
                form={`modal-form${idPrefix}`}
                className="button is-primary"
                id={`submit-modal-btn${idPrefix}`}
                style={{
                  minWidth: "8rem",
                }}
              >
                {okBtnTitle || "Submit"}
              </button>
            </span>
          )}
        </footer>
      </div>
    </div>
  );
};

Modal.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  infoOnly: PropTypes.bool,
  title: PropTypes.string,
  cb: PropTypes.shape({ submit: PropTypes.func, deny: PropTypes.func })
    .isRequired,
  children: PropTypes.node.isRequired,
  additionalBtn: PropTypes.node,
  okBtnTitle: PropTypes.string,
  cancelBtnTitle: PropTypes.string,
  showTitle: PropTypes.bool,
};

Modal.defaultProps = {
  title: "",
  infoOnly: false,
  additionalBtn: "",
  okBtnTitle: "",
  cancelBtnTitle: "",
  showTitle: true,
};

export default Modal;
