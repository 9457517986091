import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ScrollUp = () => {
  const handleScroll = () => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById("scroll-up").style.display = "block";
    } else {
      document.getElementById("scroll-up").style.display = "none";
    }
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <span id="scroll-up" onClick={scrollToTop}>
      <i className="fa fa-chevron-up" />
    </span>
  );
};

ScrollUp.propTypes = {};

export default ScrollUp;
