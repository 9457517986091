import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { navigate, withPrefix } from "gatsby";
import { isPageURL, timeSinceEvent, getRandomArrayElem } from "../utils/utils";
import img01 from "../../static/img/welcome/01.jpg";
import img02 from "../../static/img/welcome/02.jpg";
import img03 from "../../static/img/welcome/03.jpg";
import img04 from "../../static/img/welcome/04.jpg";
import img05 from "../../static/img/welcome/05.jpg";
import img06 from "../../static/img/welcome/06.jpg";
import img07 from "../../static/img/welcome/07.jpg";
import img08 from "../../static/img/welcome/08.jpg";
import img09 from "../../static/img/welcome/09.jpg";
import img10 from "../../static/img/welcome/10.jpg";
import img11 from "../../static/img/welcome/11.jpg";
import img12 from "../../static/img/welcome/12.jpg";

const imgs = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
];

const WelcomeMSg = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCb, setModalCb] = useState({
    submit: () => {
      navigate("/register");
      window.localStorage.setItem(
        "welcome_msg_close_date",
        new Date().toString(),
      );
    },
    deny: () => {
      setModalOpen(false);
      window.localStorage.setItem(
        "welcome_msg_close_date",
        new Date().toString(),
      );
    },
  });

  useEffect(() => {
    const cookiesAccepted =
      window.localStorage.getItem("cookies_accepted") === "true";
    const timeSinceClosed = timeSinceEvent("welcome_msg_close_date");

    if (
      cookiesAccepted &&
      !isPageURL("register") &&
      !isPageURL("login") &&
      !isPageURL("get-invited") &&
      timeSinceClosed >= 5
    ) {
      setModalOpen(true);
    }
  }, []);

  // that ensures we load image only when modal is shown
  useEffect(() => {
    if (modalOpen) {
      document.getElementById("welcome-popup-img").src = getRandomArrayElem(
        imgs,
      );
    }
  }, [modalOpen]);

  // useEffect(() => {
  //   document.querySelector("#sticky_root").style.marginTop = `${
  //     document.querySelector(".navbar").clientHeight
  //   }px`;
  // }, [show]);

  return (
    <Modal
      idPrefix="welcome-modal"
      title="Join today"
      isOpen={modalOpen}
      cb={modalCb}
      showTitle={false}
      // infoOnly
      okBtnTitle="Join Now"
      cancelBtnTitle="Maybe Later"
    >
      <img id="welcome-popup-img" alt="" style={{ width: "100%" }} />
      <br />
      <p className="has-text-justified">
        Join a thriving network of African professionals, businesses, entrepreneurs and investors to leverage economic and developmental opportunities
      </p>
    </Modal>
  );
};

WelcomeMSg.propTypes = {};

export default WelcomeMSg;
